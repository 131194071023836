import {useEffect, useState} from 'react';
import {
  class_price_percent,
  class_text_danger,
  format_date,
  format_float,
  format_price,
  format_value,
  has_margin,
} from '../common/util';
import LoadingSpinner from './LoadingSpinner';
import TDTicker from "./TDTicker";
import TDTrend from "./TDTrend";
import CTTService from "../services/CTTService";

function TickersIndustry({industry}) {
  const [isLoading, setLoad] = useState(true);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    CTTService.get_tickers_industry(industry).then(data => {
      data = data.filter(ticker => has_margin(ticker.ticker))
      data.sort((a, b) => {
        return b.fs_rating - a.fs_rating
      })
      setRows(data)
      setLoad(false)
    })
  }, []);

  if (isLoading) {
    return (
      <LoadingSpinner/>
    )
  }

  return (
    <div className="table-responsive">
      <table className="table table-striped table-hover table-bordered table-sm caption-top">
        <thead>
        <tr>
          <th className="th-ticker">ticker</th>
          <th>date</th>
          <th style={{minWidth:'125px'}}>value</th>
          <th>priceFlat</th>
          <th>pricePercent</th>
          <th>trend</th>
          <th className="th-base">base</th>
          <th className="th-signal">signal</th>
          <th>priceAction</th>
          <th>fs_rating</th>
        </tr>
        </thead>
        <tbody>
        {
          rows && rows.map((ticker, i) => {
            return (
              <tr key={i}>
                <TDTicker ticker={ticker} popup={false}/>
                <td className="text-end">{format_date(ticker.date)}</td>
                <td className={"text-end"}>{format_value(ticker)}</td>
                <td className={"text-end" + class_price_percent(ticker.pricePercent)}>{format_price(ticker.priceFlat)}</td>
                <td className={"text-end" + class_price_percent(ticker.pricePercent)}>{format_float(ticker.pricePercent * 100)} %</td>
                <TDTrend ticker={ticker} />
                <td className="text-center">{ ticker.base }</td>
                <td className="text-center">{ ticker.signal }</td>
                <td className="text-end">{ format_price(ticker.gia_thuc_hien) }</td>
                <td className={"text-end" + class_text_danger(ticker.fs_rating, 60)}>{ ticker.fs_rating }</td>
              </tr>
            )
          })
        }
        </tbody>
      </table>
    </div>
  );
}

export default TickersIndustry;
