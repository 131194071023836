import Modal from 'react-bootstrap/Modal';

function ModalSimple(props) {
  return (
    <Modal
      show={true}
      backdrop="static"
      keyboard={false}
      autoFocus={true}
      size={'xl'}
    >
        <button type="button" className="btn-close" onClick={props.close}></button>
        <iframe src={`https://chart.finbox.vn/mobile-chart?symbol=${props.ticker.ticker}&interval=1D&dark=true&userId=6532936b8cd6c21f16986da5`} style={{width: "100%", height: "750px", border: "none"}}/>
    </Modal>
  );

}

export default ModalSimple;
